@use "../../index.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
$primaryTextColor: #ffffff;
$secondaryTextColor: #000000;
$alertTextColor: #C7FF4F;
$alertTextColorYellow: #FFC700;

// Texts
// Weight
.text-x-thin {
  font-weight: 400;
}

.text-normal {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.text-x-bold {
  font-weight: 800;
}
// Size
.text-xxx-small {
  font-size: 9px;
  font-family: "Open Sans";
}
.text-xx-small {
  font-size: 10px;
  font-family: "Open Sans";
}

.text-x-small {
  font-size: 11px;
  font-family: "Open Sans";
}
.text-small {
  font-size: 12px;
  font-family: "Open Sans";
}

.text-medium {
  font-size: 14px;
  font-family: "Open Sans";
}

.text-large {
  font-size: 16px;
  font-family: "Open Sans";
}

.text-x-large {
  font-size: 18px;
  font-family: "Open Sans";
}

.text-xx-large {
  font-size: 24px;
  font-family: "Open Sans";
}

.text-xxx-large {
  font-size: 32px;
  font-family: "Open Sans";
}

.text-xxxx-large {
  font-size: 96px;
  font-family: "Open Sans";
}

.text-huge {
  font-size: 32px;
  font-family: "Open Sans";
}
//Text Color
.primary-color-10 {
  color: rgba($color: $primaryTextColor, $alpha: 0.1);
}

.primary-color-20 {
  color: rgba($color: $primaryTextColor, $alpha: 0.1);
}

.primary-color-25 {
  color: rgba($color: $primaryTextColor, $alpha: 0.25);
}

.primary-color-30 {
  color: rgba($color: $primaryTextColor, $alpha: 0.3);
}

.primary-color-35 {
  color: rgba($color: $primaryTextColor, $alpha: 0.35);
}

.primary-color-40 {
  color: rgba($color: $primaryTextColor, $alpha: 0.4);
}

.primary-color-50 {
  color: rgba($color: $primaryTextColor, $alpha: 0.5);
}

.primary-color-80 {
  color: rgba($color: $primaryTextColor, $alpha: 0.8);
}
.primary-color {
  color: rgba($color: $primaryTextColor, $alpha: 1);
}
.secondary-color-80 {
  color: rgba($color: $secondaryTextColor, $alpha: 0.8);
}
.alert-color {
  color: rgba($color: $alertTextColor, $alpha: 1);
}
.alert-color-yellow {
  color: rgba($color: $alertTextColorYellow, $alpha: 1);
}
.content-table{
  background: "rgba(0,0,0,0.4)";
}
.data-column{
padding: 10%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

._loading_overlay_wrapper {
  height: 100%;
  width: 100%;
}

.pdd-left{
  padding: 12px;
  padding-right: 0px;

  font-size: x-small;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.image-galery-image img {
  border-radius: 8px !important;
}

.text-capitalized {
  text-transform: lowercase;
}

.text-capitalized::first-letter {
  text-transform: uppercase;
}

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 100vh;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 1);
}

@supports (scrollbar-color: red blue) {
  * {
    scrollbar-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0);
  }
}

table tbody tr td:nth-child(2) {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-left: none;
}

table tbody tr td:nth-last-child(2) {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-right: none;
}

table tbody tr td {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
}

table tbody tr:nth-last-child(2) td {
  border-bottom: none;
}

table tbody tr:nth-child(2) td {
  border-top: none;
}

table tbody tr td:first-child {
  border: none;
}

table tbody tr td:last-child {
  border: none;
}

table tbody tr:last-child td {
  border: none;
}

table tbody tr:first-child td {
  border: none;
}
