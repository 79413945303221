@use "../../commonStyles.scss";

@font-face {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 750;
  font-stretch: normal;
  font-display: swap;
  src: url("../../../../Assets/common/fonts/HelveticaNeueLTStd-Hv.otf");
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  font-style: italic;
  font-weight: 750;
  font-stretch: normal;
  font-display: swap;
  src: url("../../../../Assets/common/fonts/HelveticaNeueLTStd-HvIt.otf");
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url("../../../../Assets/common/fonts/HelveticaNeueLTStd-Blk.otf");
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  font-style: italic;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url("../../../../Assets/common/fonts/HelveticaNeueLTStd-BlkIt.otf");
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url("../../../../Assets/common/fonts/HelveticaNeueLTStd-Bd.otf");
}

.product-titles {
  font-family: "Helvetica Neue LT Std", "Helvetica Neue";
  font-weight: 750;
  margin-top: 0;
  margin-bottom: 0;

  text-align: left;
}

.secondary-product-title {
  font-family: "Helvetica Neue LT Std", "Helvetica Neue";
  font-weight: 750;
  font-size: 48px;
  margin: 0;
  color: #ffffff;
}

.terciary-product-title {
  font-family: "Helvetica Neue LT Std", "Helvetica Neue";
  font-weight: 900;
  font-size: 24px;
  line-height: 22px;
  margin: 0;
  width: min-content;
}

.terciary-product-subtitle {
  font-family: "Helvetica Neue LT Std", "Helvetica Neue";
  font-weight: 700;
  font-size: 6px;
  line-height: 4px;
  margin-top: 0;
  margin-left: 40px;
}

.multiple {
  font-size: 96px;
  line-height: 84px;
}

.list {
  font-size: 64px !important;
  line-height:58px !important;
}

.bootom {
  font-size: 200px !important;
  line-height: 128px !important;
}

.one {
  font-size: 200px;
  line-height: 188px;
  margin-left: -0.8%;
}

.first {
  color: rgba($color: #ffffff, $alpha: 0.1);
}
.second {
  color: rgba($color: #ffffff, $alpha: 0.2);
}
.third {
  color: rgba($color: #ffffff, $alpha: 0.3);
}
.fourth {
  color: rgba($color: #ffffff, $alpha: 0.4);
}
.fifth {
  color: rgba($color: #ffffff, $alpha: 0.5);
}
.sixth {
  color: rgba($color: #ffffff, $alpha: 0.6);
}
