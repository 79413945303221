@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
body {
  margin: 0;
  height: auto;
  width: auto;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table tbody tr td:nth-child(2) {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-left: none;
}

table tbody tr td:nth-last-child(2) {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-right: none;
}

table tbody tr td {
  border: 0.5px solid rgba(255, 255, 255, 0.2);
}

table tbody tr:nth-last-child(2) td {
  border-bottom: none;
}

table tbody tr:nth-child(2) td {
  border-top: none;
}

table tbody tr td:first-child {
  border: none;
}

table tbody tr td:last-child {
  border: none;
}

table tbody tr:last-child td {
  border: none;
}

table tbody tr:first-child td {
  border: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
