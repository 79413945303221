@use "../common/commonStyles.scss";

.title-section {
  width: 72%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.1);
}

.title-section-text {
  margin-top: 35%;
  margin-left: 5%;
}

.titles-section-list {
  margin-top: 17%;
  margin-left: 5%;
  display: flex;
	flex-direction: column;
  height: 100vh;
}
.titles-section-list-back {
	margin-top: auto;
  font-size: 128px !important;
}

.titles-section-text-left {
  margin-top: 15%;
  margin-left: 10%;

}
.titles-section-text-norm {
  margin-top: 5%;
  margin-left: 10%;
}
.titles-section-center {
  text-align: right;
  margin-top: 20px;
  margin-right: 10%;
}

.paragraph {
  text-align: justify;
  width: 20%;
}
.paragraphdescription {
  text-align: justify;
  width: 80%;
  margin-left: 10%;

}

.login-section {
  width: 28%;
  height: 100vh;
	display: flex;
	flex-direction: column;

}

.login-section-logo {
  justify-content: end;
  margin-right: 43px;
  margin-top: 50px;
}

.login-section-form {
  margin-bottom: 11%;
	margin-top: auto;
}

.login-section-input {
  align-items: center;
}

.login-label-div {
  width: 67%;
  justify-content: space-between;
}
.email-label-div {
  width: 67%;
  
}

.login-label {
  align-self: end;
  margin-bottom: 1%;
}
.email-label {
  text-align: center;
  margin-bottom: 1%;
  padding-left: 15%;
  padding-right: 15%;
}
.login-label-password {
 margin-bottom: 1%;
}

.login-input {
  width: 70%;
  height: 40px;
  background: rgba($color: #ffffff, $alpha: 0.1);
  border: 0.5px solid rgba($color: #ffffff, $alpha: 0.2);
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
}

.email-input {
  width: 70%;
  height: 40px;
  background: rgba($color: #ffffff, $alpha: 1);
  border: 0.5px solid rgba($color: #ffffff, $alpha: 0.2);
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
}

.login-button-div {
  margin-top: 10%;
  width: 70%;
}

.button-email-div {
  margin-top: 2%;
  width: 50%;
}

.login-button {
  height: 40px;
  width: 100%;
  background: rgba($color: #ffffff, $alpha: 0.05);
  border-radius: 6px;
  border: 0;
}

.email-button {
  height: 40px;
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.4);
  border-radius: 6px;
  border: 0;
}
