@use "../../../servicesCommonStyles.scss";

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(180deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(180deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
