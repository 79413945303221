@use "../common/commonStyles.scss";
.products-section {
  width: 72%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.1);
}
.enter-section {
  width: 28%;
  height: 100vh;
  justify-content: space-between;
}

.enter-section-logo {
  justify-content: end;
  margin-right: 43px;
  margin-top: 50px;
}

.enter-client-name {
  margin-top: 0;
  margin-bottom: 17px;
}

.continue-button {
  margin-top: 2em;
  width: 50%;
  height: 40px;
  border: 0;
  border-radius: 6px;
  background: rgba($color: #ffffff, $alpha: 0.1);
}

.inactive-services {
  height: 20%;
  margin-left: 7%;
}

.single-inactive-service {
  align-items: center;
  min-width: 20vw;

}

.active-services {
  margin-left: 2%;
  height: 80%;
  align-items: center;
}

.activate-service {
  background: rgba($color: #07667b, $alpha: 0.1);
  border: 0.5px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 2px 5px 2px 5px;
  margin-top: 0px;
  width: fit-content;
}
.inactivate-service {
  background: rgba($color: #07667b, $alpha: 0.1);
  border: 0.5px solid #07667b;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 2px 5px 2px 5px;
  margin-top: 0px;
  width: fit-content;
}

.active-services-indicator {
  margin-right: 10px;
}

.active-service-indicator {
  align-self: end;
  margin: 0;
}

.hidden {
  visibility: hidden;
}
.hover-service:hover{
  color:white
}
